import { useState } from "react";
import useClaim from "../utils/useClaim";
import { useNavigate } from "react-router-dom";
import { appId } from "../App";
import "./Claim.css";
import closeButton from "../assets/claim/close.svg";
import loading from "../assets/claim/loading.png";
import fail from "../assets/claim/fail.png";
import buttonConfirmImg from "../assets/claim/button-confirm.svg";
import buttonCancelImg from "../assets/claim/button-cancel.svg";
import buttonTryNowImg from "../assets/claim/button-try-now.svg";
import buttonTryLaterImg from "../assets/claim/button-try-later.svg";
import buttonShareImg from "../assets/claim/button-share.svg";
import buttonDetailImg from "../assets/claim/button-detail.svg";
import reinput from "../assets/claim/reinput.svg";
import nftImage from "../assets/nft.png";

export default function Claim({handleClose}) {
  const [giftCode, setGiftCode] = useState();
  const [claimState, setClaimState] = useState();
  const post = useClaim();
  const [nft, setNFT] = useState();
  const navigate = useNavigate();
  const getNFT = () => {
    setClaimState("loading");
    post(giftCode).then((res) => {
      if (res === "fail" || res === "wrong") {
        setClaimState(res);
      } else {
        console.log(res)
        setNFT(res);
        setClaimState("success");
      }
    })
  }
  const codeOnchange = (e) => {
    setGiftCode(e.target.value);
  }
  const share = (e) => {
    navigate("/poster/" + nft.tokenId)
  }
  const detail = (e) => {
    navigate("/nft/" + nft.tokenId)
  }

  switch (claimState) {
    case "loading":
      return(
        <div className="loading">
          <div>
            <div className="loading-title">
              <img className="loading-img" src={loading} />
            </div>
            <div className="loading-content">
              <div className="input-tip">在未完成领取前，请保管好领取码</div>
            </div>
          </div>
        </div>
      )
    case "success":
      return(
        <div className="success">
          <button className="close" onClick={handleClose} >
            <img src={closeButton} alt="close" />
          </button>
          <img className="nft" src={nftImage} />
          <div className="congrats-container">
            <div className="congrats">恭喜你！获得</div>
            <div className="congrats">
              <div>《星际迷航》数字藏品</div>
            </div>
          </div>
          <div className="control">
            <button className="share" onClick={share}>
              <img src={buttonShareImg} />
            </button>
            <button className="to-detail" onClick={detail}>
              <img src={buttonDetailImg} />
            </button>
          </div>
        </div>
      )
    case "fail":
      return(
        <div className="fail">
          <img className="fail-img" src={fail} />
            <div className="control">
              <button className="try-now" onClick={getNFT}>
                <img src={buttonTryNowImg} />
              </button>
              <button className="try-later" onClick={handleClose}>
                <img src={buttonTryLaterImg} />
              </button>
            </div>
        </div>
      )
    case "wrong":
      return(
        <div className="fail">
          <img className="fail-img" src={fail} />
          <div className="control">  
            <button className="re-input" onClick={()=>{setClaimState("toClaim")}}>
              <img src={reinput} />
            </button>
          </div>
        </div>
      )
    default:
      return (
        <div className="claim">
          <div>
            <div className="title">
              <div>请输入领取卡背后的8位领取码</div>
            </div>
            <div className="content">
              <input onChange={codeOnchange} />
            </div>
          </div>
          <div className="control">
            <button className="confirm" onClick={getNFT}>
              <img src={buttonConfirmImg} />
            </button>
            <button className="close" onClick={handleClose} >
              <img src={buttonCancelImg} />
            </button>
          </div>
        </div>
      )
  }
}