import React, { useContext, useEffect, useRef, useState } from "react";
import { Image, Layer, Stage, Text } from "react-konva";
import loadingSattlelite from "../assets/loading-sattlelite.png";
import loadingBack from "../assets/loading-background.png";
import "./Poster.css";
import { AuthContext, serverAddress, appId } from "../App";
import { useParams, useNavigate } from "react-router-dom";
import point from "../assets/gold-hand.png";
import template from "../assets/poster/poster-background.png";
import nftImage from "../assets/nft.png";

export default function Poster() {

  const { state } = useContext(AuthContext);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (state.isLogin === false) {
      navigate("/");
    }
  },[state.isLogin])

  const ratio = window.devicePixelRatio;

  const [deviceWidth] = useState(window.innerWidth);
  const stageRef = useRef();
  const output = useRef();
  const [nft, setNft] = useState();
  const [loaded, setLoaded] = useState(false);
  const [loadingDot, setDot] = useState("");
  const [templateLoaded, setTemplateLoaded] = useState(false);
  const [nftLoaded, setNftLoaded] = useState(false);
  const [pressed, setPressed] = useState(false);

  const [client, setOssClient] = useState(null);

  const uiWidth = 375;
  const uiHeight = 812;
  const scale = deviceWidth / uiWidth;

  const imgWidth = 750;

  const [templateImg, setTemplate] = useState();
  const [owner, setOwner] = useState();
  const [updateTime, setUpdateTime] = useState();
  const params = useParams();

  const getSubStr = (str) => {
    var subStr1 = str.substr(0, 7);
    var subStr2 = str.substr(str.length - 17, 17);
    var subStr = subStr1 + "..." + subStr2;
    return subStr;
  };

  useEffect(() => {
    if (state.isLogin) {
      let headers = new Headers();
      headers.append("appId", appId);
      headers.append("accessToken", state.accessToken);

      fetch(serverAddress + "/ststoken",{
        method: "GET",
        headers: headers,
      }).then((res) => {
        if(res.status === 200){
          res.json().then((res) => {
            if(res.returnCode === "20000"){
              const {accessKeyID,accessKeySecret,securityToken} = res.data;
              const client = new window.OSS.Wrapper({
                region:'oss-cn-shanghai',
                accessKeyId: accessKeyID,
                accessKeySecret,
                stsToken: securityToken,
                bucket: 'bucket-elements'
              })
              setOssClient(client);
            }
          })
        }
      });

      fetch(serverAddress + "/poster/"+ params.id, {
        method: "GET",
        headers: headers,
      }).then((res) => {
        if (res.status === 200) {
          res.json().then((res) => {
            if (res.returnCode === "20000") {
              let nftImg = document.createElement("img");
              nftImg.crossOrigin = "anonymous";
              nftImg.src = nftImage;
              nftImg.onload = () => {
                setNftLoaded(true);
              };
              setNft(nftImg);

              let tempImg = document.createElement("img");
              tempImg.crossOrigin = "anonymous";
              tempImg.src = template;
              tempImg.onload = () => {
                setTemplateLoaded(true);
              };
              setTemplate(tempImg);

              let owner = getSubStr(res.data.owner);
              setOwner(owner);
              setUpdateTime(res.data.updateTime);
            } else {
              return null;
            }
          });
        } else {
          return null;
        }
      });
    }
  }, [state.isLogin]);

  useEffect(() => {
    if (nftLoaded && templateLoaded && client !== null) {
      setTimeout(() => {
        console.log("hello")
        const canvas = stageRef.current.toCanvas({pixelRatio: ratio});
        let path = "seazen/poster/" + params.id + '.jpg';
        client.list({prefix:path}).then((res) => {
          if(res.objects && res.objects.length > 0){
            let url = "https://elements.cocafe.co/" + path;
            let result = document.createElement("img");
            result.crossOrigin = "anonymous";
            result.src = url;
            output.current.appendChild(result);
            output.current.scrollTo(0,0);
            result.onload = () => {
              setLoaded(true);
            }
          }
          else {
            canvas.toBlob((blob) => {
              const reader = new FileReader();
              reader.readAsArrayBuffer(blob);
              reader.onload = function (event) {
                const buffer = new window.OSS.Buffer(event.target.result);
                client.put(path, buffer).then((res) => {
                  let url = "https://elements.cocafe.co/" + path;
                  let result = document.createElement("img");
                  result.crossOrigin = "anonymous";
                  result.src = url;
                  output.current.appendChild(result);
                  output.current.scrollTo(0,0);
                  result.onload = () => {
                    setLoaded(true);
                  }
                }).catch(function(err){
                  console.log(err);
                });
              }
            },'image/jpeg',1);
          }
        });
      },500);
    }
  }, [nftLoaded, templateLoaded, client]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (loadingDot.length < 3) {
        let newDot = loadingDot + ".";
        setDot(newDot);
      } else if (loadingDot.length === 3) {
        setDot("");
      }
    }, 500);
    return () => {clearTimeout(timer);}
  }, [loadingDot]);

  const handleTap = () => {
    if(loaded) setPressed(true);
  }

  return (
    <div>
      {!pressed &&(
        <div id="poster-enter" style={{ width: deviceWidth, height: window.screen.height, zIndex: 3, backgroundColor: "white" }} onClick={handleTap} onTouchStart={handleTap}>
          <img src={loadingBack} style={{ width: deviceWidth, height: window.screen.height }}/>
          <div id="poster-loading">
            <img src={loadingSattlelite} width={126} />
            <div className="mt-5 text-sm text-white">
              { loaded ? "轻触屏幕，然后长按图片保存到本地相册" : `努力加载中${loadingDot}` }
            </div>
            {loaded &&
            <div style={{ width: "29px", animation: "blink 1.5s linear infinite", position: 'absolute', top: '170px', zIndex: 3 }} >
              <img src={point}/>
            </div>}
          </div>
        </div>
      )}
      <div style={{ position: "absolute", top: 0, left: 0, zIndex: 1,display: 'flex',alignItems:'center',justifyContent: 'center' }} ref={output}></div>
      {!loaded &&
      <Stage className="absolute left-0 top-0" width={uiWidth * scale} height={uiHeight * scale} ref={stageRef} >
        <Layer>
          <Image image={templateImg} scale={{ x: scale * (uiWidth / imgWidth), y: scale * (uiWidth / imgWidth) }} />
          <Image image={nft} width={171.54 * scale} height={215 * scale} x={137 * scale} y={265 * scale} />
          <Text text="发行方：吾悦广场 × 中国航天神舟传媒" width={235 * scale} x={105.37 * scale} y={550 * scale} fontSize={12  * scale} fontFamily="FZZhengHeiS" fill="#4DFFFF" align="right" />
          <Text text={"创建时间："+ updateTime} width={235 * scale} x={105.37 * scale} y={570 * scale} fontSize={12  * scale} fontFamily="FZZhengHeiS" fill="#4DFFFF" align="right"/>
          <Text text="创作作者：李磊" width={235 * scale} x={105.37 * scale} y={590 * scale} fontSize={12  * scale} fontFamily="FZZhengHeiS" fill="#4DFFFF" align="right" />
          <Text text={"所有者地址：" + owner} width={235 * scale} x={105.37 * scale} y={610 * scale} fontSize={12 * scale} fontFamily="FZZhengHeiS" fill="#4DFFFF" align="right" />
          <Text text={"温馨提示"} x={285.5 * scale} y={654 * scale} fontSize={12 * scale} fontFamily="FZZhengHeiS" fill="#060014" />
          <Text text="本数字藏品版权由发行方或原创者拥有，除另行取得版权拥有者书面同意外，用户不得将数字藏品用于任何商业用途。请勿对数字藏品进行炒作、场外交易、欺诈，或以任何其他非法方式进行使用。"
            x={42 * scale} y={(680 * scale)} width={(298 * scale)} fontSize={10 * scale} fontFamily="FZZhengHeiS" lineHeight={1.4} align="center" fill="#FFFFFF" opacity="0.8" align="center"  />
        </Layer>
      </Stage>
      }

    </div>
  );
}
