import { AuthContext } from "../App";
import { useContext, useState } from "react";
import Login from "./Login";
import { Dialog, Drawer } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from "react-router-dom";
import useLogout from "../utils/useLogout";
import "./MenuBar.css";
import menuButton from "../assets/menu/menu-button.svg";

export default function MenuBar() {
  const { state } = useContext(AuthContext);
  const [loginOpen, setLoginOpen] = useState(false);

  
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const profileOpen = Boolean(profileAnchorEl);
  const handleProfileClick = (event) => {
    if (state.isLogin) {
      setProfileAnchorEl(event.currentTarget);
    } else {
      setLoginOpen(true);
    }
  };
  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  const logout = useLogout();
  const handleLogout = () => {
    setProfileAnchorEl(null);
    logout();
  }

  const handleLoginClose = () => {
    setLoginOpen(false);
  }
  
  return (
    <div className="menu-bar flex justify-between">
      <div className="menu-button text-white"><button onClick={handleClick}>菜单</button></div>
      <div className="menu-button profile text-white"><button onClick={handleProfileClick}>{state.isLogin?"个人中心":"登录"}</button></div>
      <Drawer
        anchor="bottom"
        open={loginOpen}
        onClose={handleLoginClose}
        sx={{
          "& .MuiDrawer-paper": {
            background: "black",
            borderRadius: "30px 30px 0px 0px"
          }
        }}
      > 
        <div className="login-container">
          <Login handler={handleLoginClose} />
        </div>
      </Drawer>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor:"#232928"
          },
          "& .MuiMenuItem-root, a": {
            fontSize: "12px",
            color: "#9A9A9A"
          }
        }}
      >
        <Link to="/"> <MenuItem onClick={handleClose}>主页</MenuItem></Link>
        <Link to="/about"><MenuItem onClick={handleClose}>关于</MenuItem></Link>
      </Menu>
      <Menu
        id="profile-menu"
        anchorEl={profileAnchorEl}
        open={profileOpen}
        onClose={handleProfileClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          "& .MuiPaper-root" :{
            color: "#9A9A9A",
            backgroundColor:"#232928"
          },
          "& .MuiMenuItem-root, a": {
            fontSize: "12px",
            color: "#9A9A9A"
          }
        }}
      >
        <MenuItem onClick={handleProfileClose}><Link to="/mynft">我的数字资产</Link></MenuItem>
        <MenuItem onClick={handleLogout}>退出登录</MenuItem>
      </Menu>
    </div>
  );
}
