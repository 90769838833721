import { AuthContext, serverAddress, appId } from "../App";
import { useContext, useEffect, useState } from "react";
import Login from "../components/Login"
import { Dialog, Drawer } from "@mui/material";
import "./Home.css";
import Claim from "../components/Claim";
import MenuBar from "../components/MenuBar";
import { useNavigate } from "react-router";
import textClaimNow from "../assets/home/text-claim-now.svg";
import textMyNft from "../assets/home/text-my-nft.svg";
import backdrop from "../assets/home/backdrop.png";

import nft from "../assets/nft.png"

export default function Home() {

  const { state } = useContext(AuthContext);
  const [loginOpen, setLoginOpen] = useState(false);
  const [claimOpen, setClaimOpen] = useState(false);
  const [claimResult, setClaimResult] = useState(false);
  const navigate = useNavigate();

  const handleLoginClose = () => {
    setLoginOpen(false);
  }
  const handleClaimClose = () => {
    setClaimOpen(false);
  }

  const continueClaim = () => {
    handleLoginClose();
    setClaimOpen(true);
  }

  const [claimed, setClaimed] = useState();
  useEffect(() => {
    if (state.isLogin) {
      let headers = new Headers();
      headers.append("appId", appId);
      headers.append("accessToken", state.accessToken);
      fetch(serverAddress + "/user/nft", {
        method: "GET",
        headers: headers,
      })
        .then((res) => {
          if (res.status === 200) {
            res.json().then((res) => {
              if (res.returnCode === "20000") {
                if (res.data.tokenId.length > 0) {
                  setClaimed(true);
                }
              }
            });
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
  },[state.isLogin])

  const claim = () => {
    if (state.isLogin) {
      if (!claimed) {
        setClaimOpen(true);
      } else {
        navigate("/mynft");
      }
    } else {
      setLoginOpen(true);
    }
  }

  return (
    <div className="home">
      <MenuBar />
      <div className="main flex flex-col items-center">
        <div className="nft-container">
          <img className="nft" src={nft} alt="nft" />
        </div>
        <div className="button-claim" onClick={claim}><img src={state.isLogin && claimed ? textMyNft: textClaimNow}></img></div>
        <div onClick={() => { setClaimOpen(true) }}><p className="underline text-white mt-4 text-sm">{state.isLogin && claimed ? "获取更多" : ""}</p></div>
      </div>
      <div className="notice-container">
        <div className="notice"></div>
      </div>
      <Drawer
        anchor="bottom"
        open={loginOpen}
        onClose={handleLoginClose}
        sx={{
          "& .MuiDrawer-paper": {
            background: "transparent",
            borderRadius: "30px 30px 0px 0px"
          },
          backdropFilter: "blur(46.2108px)"
        }}
      > 
        <div className="login-container">
          <Login handler={continueClaim}/>
        </div>
      </Drawer>

      <Dialog fullScreen open={claimOpen} onClose={handleClaimClose} sx={{"& .MuiDialog-paper": {backgroundImage: "url("+backdrop+")", overflow:"visible", display:"flex", justifyContent:"center", alignItems:"center"}}}>
        <Claim handleClose={handleClaimClose}/>
      </Dialog>
    </div>
  )
}