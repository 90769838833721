import { createContext, useEffect, useReducer } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import MyNFT from "./pages/MyNFT";
import Detail from "./pages/Detail";
import Poster from "./pages/Poster";
import About from "./pages/About";
import Agreement from "./components/Agreement";
import './App.css';

export const AuthContext = createContext();
export const portalAddress = process.env.REACT_APP_PORTAL_ADDRESS;
export const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
export const appId = "Szs123";

const initialState = {
  isLogin: null,
  userId: null,
  accessToken: null, 
}

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("userId", action.payload.userId);
      localStorage.setItem("accessToken", action.payload.accessToken);
      return {
        ...state,
        isLogin: true,
        userId: action.payload.userId,
        accessToken: action.payload.accessToken,
      };
    case "LOGOUT":
      localStorage.removeItem("userId");
      localStorage.removeItem("accessToken");
      return {
        ...state,
        isLogin: false,
        userId: null,
        accessToken: null,
      };
  }
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(async() => {
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      let headers = new Headers();
      headers.append("accessToken", localStorage.getItem("accessToken"));
      try {
        const response = await fetch(
          portalAddress + "/user/token",
          {
            method: "GET",
            headers: headers,
          }
        );
        const json = await response.json();
        if (json.returnCode === "20000") {
          dispatch({
            type: "LOGIN",
            payload: {
              isLogin: true,
              userId: localStorage.getItem("userId"),
              accessToken: localStorage.getItem("accessToken"),
            }
          })
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      dispatch({
        type: "LOGOUT",
      })
    }
  },[])

  return (
    <div className="App max-w-lg m-auto bg-black">
      <AuthContext.Provider value = {{state, dispatch}}>
        <Routes>
          <Route path = "/" element = {<Home />} />
          <Route path = "/mynft" element = {<MyNFT />} />
          <Route path = "/nft/:id" element = {<Detail />} />
          <Route path = "/poster/:id" element = {<Poster />} />
          <Route path = "/about" element = {<About />} />
        </Routes>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
