import { useEffect, useContext, useState } from "react";
import { ImageViewer } from 'antd-mobile'
import { serverAddress, AuthContext, appId } from "../App";
import { useParams, useNavigate } from "react-router-dom";
import MenuBar from "../components/MenuBar";
import { Link } from "react-router-dom";
import back from "../assets/mynft/back.svg";
import textDetailTitle from "../assets/detail/title.svg";
import nft from "../assets/detail/nft.png";
import nftBorder from "../assets/detail/nft-container.png";
import logo from "../assets/detail/logo.png";
import "./Detail.css";

export default function Detail({id}) {
  const { state, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (state.isLogin === false) {
      navigate("/");
    }
  },[state.isLogin])
  const [detail, setDetail] = useState({});
  const params = useParams();

  const getSubStr = (str) => {
    var subStr1 = str.substr(0, 7);
    var subStr2 = str.substr(str.length - 17, 17);
    var subStr = subStr1 + "..." + subStr2;
    return subStr;
  };
  const [visible, setVisible] = useState();

  useEffect(async () => {
    if (state.accessToken) {
      let headers = new Headers();
      headers.append("appId", appId);
      headers.append("accessToken", state.accessToken);
      try {
        const response = await fetch(serverAddress + "/nft/detail/" + params.id, {
          method: "GET",
          headers: headers,
        });
        const json = await response.json();
        if (json.returnCode === "20000") {
          console.log(json.data.updateTime)
          setDetail({
            updateTime: json.data.updateTime,
            owner: getSubStr(json.data.owner),
          });
        } else {
          console.log(json.message);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  },[state.accessToken])
  return (
    <div className="detail">
      <MenuBar />
      <div className="title-bar">
        <Link to="/mynft" className="back"><img src={back} /></Link>
        <div className="page-title">星际迷航</div>
      </div>
      <div className="nft-container">
        <img className="nft-border" src={nftBorder} onClick={() => { setVisible(true) }} />
        <img className="nft" src={nft}/>
        <ImageViewer image={nft} visible={visible} onClose={() => { setVisible(false) }} />
      </div>
      <div className="nft-information">
        <div className="information-title mb-8"><img src={textDetailTitle}/></div>
        <div className="information-content">
          <div className="information-item">
            <div className="label">发行方：</div>
            <div className="value"><img className="w-28" src={logo} /></div>
          </div>
          <div className="information-item">
            <div className="label">创建时间：</div>
            <div className="value">{detail.updateTime}</div>
          </div>
          <div className="information-item">
            <div className="label">创作作者：</div>
            <div className="value">李磊</div>
          </div>
          <div className="information-item">
            <div className="label">所有者地址：</div>
            <div className="value">{detail.owner}</div>
          </div>
        </div>
      </div>
      <div className="notice-container">
        <div className="notice"></div>
      </div>
      <div className="poster-generate">
        <button onClick={()=>{navigate("/poster/" + params.id)}}></button>
      </div>
    </div>
  )
}