import { useEffect, useState } from "react";
import useLogin from "../utils/useLogin";
import useSMS from "../utils/useSMS";
import Checkbox from '@mui/material/Checkbox';
import textSignLogin from "../assets/login/text-sign-login.svg";
import Agreement from "../components/Agreement";
import { Dialog } from "@mui/material";
import backdrop from "../assets/agreement/agreement-background.svg";

import "./Login.css";
import { Link } from "react-router-dom";

export default function Login({ handler }) {

  const [phone, setPhone] = useState();
  const [code, setCode] = useState();

  const [phoneValidate, setPhoneValidate] = useState(false);
  const [codeValidate, setCodeValidate] = useState(false);
  
  const [btnBool, setBtnBool] = useState(false);
  const [btnText, setBtnText] = useState("获取验证码");
  const [count, setCount] = useState(0);
  
  const phoneOnchanged = (e) => {
    setPhoneValidate(false);
    setPhone(e.target.value);
  };

  const codeOnchanged = (e) => {
    setCodeValidate(false);
    setCode(e.target.value);
  };

  const sms = useSMS(phone);
  const getSMS = async () => {
    if (/^1\d{10}$/.test(phone)) {
      setBtnBool(true);
      setCount(60);
      sms().then((res) => {
        if (res === false) {
          setPhoneValidate(true);
        }
      });
    } else {
      setPhoneValidate(true);
    }
  };

  const login = useLogin(phone, code)
  const handleConfirm = () => {
    if (/^1\d{10}$/.test(phone)) {
      if (checked === true) {
        login().then((res) => {
          if (res === false) {
            setCodeValidate(true);
          } else {
            handler();
          }
        })
      }
    } else {
      setPhoneValidate(true);
    }
  };

  const [checked, setChecked] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (count > 1) {
      setBtnText("重新发送 "+ count +"s");
      setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    }
    if (count === 1) {
      setBtnText("重新发送 "+ count +"s");
      setTimeout(() => {
        setBtnText("获取验证码");
        setCount(0);
        setBtnBool(false);
      }, 1000);
    }
  }, [count]);

  const [agreementOpen, setAgreementOpen] = useState(false);
  
  return (
    <div className="relative login" style={{padding: "0 14px"}}>
      <div className="relative">
        <div className="flex justify-center" style={{padding: "38.6px 0 28.6px"}}>
          <div className="text-2xl text-white font-medium" >
            <img src={textSignLogin} />
          </div>
        </div>
        <div>
          <div className="flex phone">
            <div className="relative top-2 text-white font-semibold" style={{ color: "#4DFFFF"}}>手机号</div>
            <div className="flex flex-auto flex-col" style={{marginLeft: "21px"}}>
            <input className="phone flex-auto" type="tel" size="1" onChange={phoneOnchanged} placeholder="请输入手机号" style={{ backgroundColor: "rgba(255, 255, 255, 0.0994301)" }}/>
              <div className="validate" style={{ height: "1.125rem", }} >
                {phoneValidate && "请输入正确的手机号"}
              </div>
            </div>
          </div>
          <div className="flex code">
            <div className="relative top-2 text-white font-semibold" style={{color:"#4DFFFF"}}>验证码</div>
            <div className="flex flex-auto flex-col" style={{marginLeft: "21px"}}>
            <input className="code" type="tel" size="1" onChange={codeOnchanged} placeholder="请输入验证码" style={{ backgroundColor: "rgba(255, 255, 255, 0.0994301)" }}/>
              <div className="validate" style={{ height: "1.125rem", }} >
                {codeValidate && "验证码不正确"}
              </div>
            </div>
            <button onClick={getSMS} disabled={btnBool} className="login code font-semibold">
              {btnText}
            </button>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center">
        <Checkbox onChange={handleChange} sx={{ '& .MuiSvgIcon-root': { fontSize: 18, color: "#00E0DB" } }} />
        <div className="text-white text-sm flex items-center">我已阅读并同意<div onClick={()=>{setAgreementOpen(true)}} className="relative" style={{color:"#00E0DB"}}>《用户协议》</div></div>
      </div>

      <div className="flex justify-center mb-8 relative">
        <button className="login next relative" onClick={handleConfirm} disabled={!checked}>
          <svg width="162" height="33" viewBox="0 0 162 33" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M161.794 16.4489H161.795L150.995 0.144531H148.451L159.251 16.4488H159.25L148.451 32.7531H150.995L161.794 16.4489Z" fill={checked?"#4DFFFF":"#4C5262"}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.10529 16.4489H3.10541L13.9064 0.144531H11.3637L0.562747 16.4488H0.5625L11.3635 32.7531H13.9062L3.10529 16.4489Z" fill={checked?"#4DFFFF":"#4C5262"}/>
            <path d="M81.1793 32.1494H18.1452L7.83569 16.4489L18.1452 0.748396H81.1793H144.212L154.522 16.4489L144.212 32.1494H81.1793Z" fill="#060014" fill-opacity="0.5" stroke={checked?"#4DFFFF":"#4C5262"} stroke-width="1.20773"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M106.571 15.6627V13.5087H100.669V12.3879H105.958V10.234H100.669V9.21826H97.6924V13.5087H95.8186V9.76114H92.754V13.5087H90.8276V15.6627H106.571ZM64.1917 23.2806V11.5475H71.4942V9.37601H55.856V11.5475H61.1971V23.2806H64.1917ZM68.9199 19.813C69.2585 19.813 69.7663 19.7722 70.4435 19.6904C69.9765 19.3636 69.6554 19.0191 69.4803 18.6572C69.3052 18.2953 69.1534 17.7758 69.025 17.0987L68.4296 13.7363H65.4L66.153 17.6766C66.3048 18.4471 66.5937 18.9958 67.0199 19.3227C67.446 19.6496 68.0793 19.813 68.9199 19.813ZM89.199 15.2599V17.3788H73.158V15.2599H89.199ZM93.1304 20.2332C92.5758 20.3383 91.8491 20.3908 90.9501 20.3908C91.3121 20.099 91.6185 19.7633 91.8695 19.3839C92.1205 19.0045 92.2986 18.6396 92.4036 18.2894L93.1041 16.2055H96.0987L95.2581 18.4995C95.0713 19.0132 94.8086 19.4043 94.4701 19.6729C94.1315 19.9414 93.6849 20.1282 93.1304 20.2332ZM102.893 23.4555L106.571 16.2055H103.454L100.809 21.284H93.034C92.2518 21.284 91.6039 21.0505 91.0902 20.5835C91.0902 21.2956 91.1661 21.8589 91.3179 22.2734C91.4696 22.6878 91.7235 22.9885 92.0796 23.1753C92.4357 23.3621 92.9173 23.4555 93.5244 23.4555H102.893ZM100.809 16.2055V20.3908H97.8148V16.2055H100.809Z" fill={checked?"#4DFFFF":"#4C5262"}/>
          </svg>
        </button>
      </div>

      <Dialog fullScreen open={agreementOpen} onClose={()=>{setAgreementOpen(false)}} sx={{"& .MuiDialog-paper": {backgroundImage: "url("+backdrop+")", }}}>
        <Agreement handleClose={()=>{setAgreementOpen(false)}}/>
      </Dialog>
    </div>
  );
}