
import { useState, useEffect, useContext } from "react";
import { AuthContext, serverAddress, appId } from "../App";
import MenuBar from "../components/MenuBar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { Dialog } from "@mui/material";
import Claim from "../components/Claim";
import back from "../assets/mynft/back.svg";
import plus from "../assets/mynft/plus.svg";
import "./MyNFT.css";
import nftImg from "../assets/nft.png";
import backdrop from "../assets/home/backdrop.png";

export default function MyNFT() {

  const { state } = useContext(AuthContext);
  const [NFTList, setNFTList] = useState([]);
  const [claimOpen, setClaimOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (state.isLogin === false) {
      navigate("/");
    }
  },[state.isLogin])
  
  useEffect(() => {
    let headers = new Headers();
    headers.append("appId", appId);
    headers.append("accessToken", state.accessToken);
    fetch(serverAddress + "/user/nft", {
      method: "GET",
      headers: headers,
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((res) => {
            if (res.returnCode === "20000") {
              setNFTList(res.data.tokenId);
            }
          });
        }
      })
      .catch((e) => {
        console.log("error", e);
      });
  }, [state.accessToken]);

  return (
    <div className="my-nft">
      <MenuBar />
      <div className="main">
        <div className="title-bar">
          <Link to="/" className="back"><img src={back} /></Link>
          <div className="page-title">已拥有的航天数字藏品</div>
        </div>
        <div className="contract">合约地址：cfx:aceftgh...1vyabpgk3ed</div>
        <div className="grid grid-cols-2 relative">
          {NFTList.map((id, index) => {
            return (
              <div key={index} className={"nft-wrapper item" + index}>
                <div
                  className={"card-container item" + index + " relative"}
                >
                  <div className="relative flex flex-col justify-center items-center">
                    <Link to={"/nft/" + id} className="nft-card">
                      <img className="nft" src={nftImg} />
                    </Link>
                    <div className="card-title">
                      <p className="text-center text-sm font-medium name">星际迷航-{id}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className={"nft-wrapper item"} onClick={()=>{setClaimOpen(true)}}>
            <div
              className={"card-container relative"}
            >
              <div className="relative flex flex-col justify-center items-center">
                <div className="nft-card flex justify-center items-center">
                  <img src={plus}></img>
                </div>
                <div className="card-title">
                  <p className="text-center text-sm font-medium name">添加数字藏品</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="notice-container">
          <div className="notice"></div>
        </div>
      </div>

      <Dialog fullScreen open={claimOpen} onClose={() => {setClaimOpen(false)}} sx={{"& .MuiDialog-paper": {backgroundImage: "url("+backdrop+")", overflow:"visible", display:"flex", justifyContent:"center", alignItems:"center"}}}>
        <Claim handleClose={() => {setClaimOpen(false)}} />
      </Dialog>
    </div>
  )
}