import "./About.css";
import about from "../assets/about.svg";

export default function About() {
  return (
    <div className="about">
      <div><img className="about-img" src={about} /></div>
      <div>
        <div className="text-xs text-justify leading-6 mb-8">
          <p>航天是创新驱动社会发展的重点领域，航天精神更是国家崇尚的中华民族时代精神。新城控股旗下吾悦广场与中国航天神州传媒达成合作，旨在更好地传递航天精神代表的中国力量。 </p>
          <p>航天数字藏品是由吾悦广场推出的，围绕着中国航天文化IP，与知名艺术家李磊携手创作，并铸造在高性能区块链Conflux上的数字资产作品。 </p>
          <p>李磊1965年10月8日生于上海市。现任上海戏剧学院舞美系绘画专业教授、上海市美术家协会副主席、上海市政协委员、一级美术师。李磊在北京、上海、香港、纽约、伦敦、法兰克福、林茨、阿姆斯特丹、博克斯梅尔、布鲁塞尔举办过个人画展。在中国、德国、英国出版了多部著作。作品被众多机构和个人收藏。 </p>
          <p>数字藏品又称为“非同质化通证”，是基于区块链智能合约发行的、具有唯一性和不可分割性的数字资产。区块链智能合约作为区块链上的技术容器，可以用来承载各类内容，如艺术品、收藏品、游戏、音乐等，使得这些内容的唯一性、稀缺性得到保障。同时区块链智能合约也可以保护内容在链上的知识产权，防止篡改与造假。迄今，数字藏品已成为区块链上一类成熟的场景应用。 </p>
        </div>
      </div>
      <div>
        <div className="text-lg text-white font-medium">领取细则</div>
        <div className="divider"></div>
        <div className="text-xs text-justify leading-6">
          <p>一、	适用对象：领取码用户（指拥有有效数字藏品领取卡的用户，“有效”指该数字领取卡对应的领取码未使用过）使用领取码在航天数字藏品发行站点完成验证并获得数字藏品。每个领取码仅可对应一次航天数字藏品领取机会，完成验证后即失效。 </p>
          <p>二、	领取方式：领取码用户需要在站点进行验证并授权绑定手机号作为领取数字藏品以及提取数字藏品的唯一凭证。特别提示：用户的手机号及领取码请务必自行妥善保管。因用户个人原因泄露、遗失、转号而导致数字藏品遗失或被盗或冒领的后果及损失由该用户自行承担。 </p>
          <p>三、	领取码用户在本站点领取数字藏品后，可通过两种途径查看所持有的数字藏品：路径1：本站点-首页-个人中心-我的数字藏品。该查看路径会保留一年，至2022年11月23日20:00:00关闭。  路径2：通过conflux浏览器（地址：confluxscan.net）查询数字藏品的智能合约地址来浏览所持有的数字藏品。 </p>
          <p>四、	领取码用户在2021年02月14日后，可将已领取的数字藏品提取至个人的区块链钱包。具体提取时间段为：2022年02月14日 00:00 - 2022年11月23日20:00:00。用户不得对所持有的数字藏品进行转赠、售卖、或以其它任何形式进行转让。 用户在提取后需妥善保管自己的数字藏品和自己的区块链钱包私钥。提取后发生的一切遗失、失窃、无法找回等情形，均由用户自行承担。如果中奖用户不提取航天数字藏品至自己的个人钱包，用户依旧可将航天数字藏品保管在本站点。 关于如何创建conflux区块链钱包和中国航天数字藏品提取, 具体请根据conflux网站（地址：https://portal.confluxnetwork.org/）公布的指导操作。 </p>
          <p>五、	领取码用户领取数字藏品后，利用该数字藏品进行的违约、违法行为，由用户个人承担相关法律责任。</p>
        </div>
      </div>
    </div>
  )
}